.input-main-container {
  width: 100%;
  margin-bottom: 16px;
  position: relative;
  box-sizing: border-box;
  padding: 0px 8px;
}
.input-main-container.input-main-container-no-margin {
  margin-bottom: 0px;
}
.input-main-container.input-main-container-half {
  width: 50%;
}
.input-main-container.input-main-container-third {
  width: 33.33%;
}
.input-main-container.columns-1 {
  width: 8.3333333333%;
}
.input-main-container.columns-2 {
  width: 16.6666666667%;
}
.input-main-container.columns-3 {
  width: 25%;
}
.input-main-container.columns-4 {
  width: 33.3333333333%;
}
.input-main-container.columns-5 {
  width: 41.6666666667%;
}
.input-main-container.columns-6 {
  width: 50%;
}
.input-main-container.columns-7 {
  width: 58.3333333333%;
}
.input-main-container.columns-8 {
  width: 66.6666666667%;
}
.input-main-container.columns-9 {
  width: 75%;
}
.input-main-container.columns-10 {
  width: 83.3333333333%;
}
.input-main-container.columns-11 {
  width: 91.6666666667%;
}
.input-main-container.columns-12 {
  width: 100%;
}
.input-main-container .input-label {
  display: block;
  margin-bottom: 4px;
  font-weight: 300;
  text-align: left;
}
.input-main-container .input-sublabel {
  display: block;
  margin-bottom: 4px;
  font-weight: 300;
  text-align: left;
  color: #92a7ac;
  line-height: 1.25;
}
.input-main-container .card-container {
  border: 1px solid transparent;
  box-sizing: border-box;
}
.input-main-container .card-container.card-container-active {
  border-color: #ce544f;
}
.input-main-container.input-main-container-white-label .input-label {
  color: #ffffff;
}
.input-main-container input,
.input-main-container select,
.input-main-container textarea {
  font-family: "Caros";
  font-weight: 400;
  appearance: none;
  height: 38px;
  line-height: 38px;
  width: 100%;
  border-radius: 5px;
  border: 1px solid #c8ced3;
  background-color: #ffffff;
  box-sizing: border-box;
  padding: 0px 12px;
  color: #3b3b3b;
  font-size: 14px;
  font-weight: 500;
  outline: 0;
  transition: all 0.1s;
}
.input-main-container input:focus,
.input-main-container select:focus,
.input-main-container textarea:focus {
  border-color: #92a7ac;
}
.input-main-container input.dates-input-error, .input-main-container input.input-field-error,
.input-main-container select.dates-input-error,
.input-main-container select.input-field-error,
.input-main-container textarea.dates-input-error,
.input-main-container textarea.input-field-error {
  border-color: #ce544f;
}
.input-main-container input:read-only,
.input-main-container textarea:read-only {
  background-color: #f5f9fa;
  cursor: not-allowed;
}
.input-main-container input:read-only:focus,
.input-main-container textarea:read-only:focus {
  border-color: #c8ced3;
}
.input-main-container select.select-input-error {
  border-color: #ce544f;
}
.input-main-container textarea {
  height: 200px;
  line-height: 1.3;
  padding: 12px;
  resize: none;
}
.input-main-container .dates-input-container {
  width: 100%;
}
.input-main-container .dates-input-container .react-datepicker-popper {
  z-index: 2000;
}
.input-main-container .dates-input-container .react-datepicker-wrapper {
  width: 100%;
}
.input-main-container .dates-input-container .dates-input.dates-input-disabled {
  background-color: #f5f9fa;
  border-color: #c8ced3;
  cursor: not-allowed;
}
.input-main-container .color-input-container .circle-picker {
  width: 100% !important;
  padding: 5px 0 10px;
}
.input-main-container .checkbox-input-container {
  display: flex;
}
.input-main-container .checkbox-input-container input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 0px;
  height: 20px;
  width: 20px;
  min-height: 20px;
  min-width: 20px;
  max-height: 20px;
  max-width: 20px;
  margin-right: 8px;
  position: relative;
  background-color: #ffffff;
  cursor: pointer;
}
.input-main-container .checkbox-input-container input:checked {
  background-color: #0089b6;
  border-color: #0089b6;
}
.input-main-container .checkbox-input-container input:checked:before {
  position: absolute;
  content: "";
  display: block;
  height: 3px;
  width: 5px;
  background-color: #ffffff;
  transform: rotate(45deg);
  top: 9px;
  left: 4px;
}
.input-main-container .checkbox-input-container input:checked:after {
  position: absolute;
  content: "";
  display: block;
  height: 3px;
  width: 10px;
  background-color: #ffffff;
  transform: rotate(-45deg);
  top: 8px;
  left: 6px;
}
.input-main-container .checkbox-input-container .input-label {
  margin: 0px;
}
.input-main-container .checkbox-input-container .input-label a {
  color: #0089b6;
  font-weight: 500;
}
.input-main-container .checkbox-input-container .input-label a:hover {
  text-decoration: underline;
}
.input-main-container.input-main-container-inline {
  display: flex;
  align-items: center;
  width: auto;
}
.input-main-container.input-main-container-inline .input-label {
  margin-bottom: 0px;
  white-space: nowrap;
}

.form-errors-container {
  padding: 8px 0px 0px;
}
.form-errors-container.form-errors-container-margin-bottom {
  margin-bottom: 15px;
}
.form-errors-container .form-error {
  color: #ce544f;
  text-align: left;
}

@media screen and (max-width: 700px) {
  .input-main-container .input-label {
    margin-bottom: 3px;
  }
  .input-main-container.input-main-container-half {
    width: 100%;
  }
  .input-main-container.input-main-container-third {
    width: 100%;
  }
  .form-errors-container {
    padding-top: 3px;
  }
}