@font-face {
  font-family: "Caros";
  font-style: normal;
  font-weight: 400;
  src: local("Caros Soft Regular"), url("{{ BEACHAROUND_FONTS_FOLDER }}/CarosSoft.woff") format("woff");
  font-display: swap;
}
@font-face {
  font-family: "Caros";
  font-style: normal;
  font-weight: 300;
  src: local("Caros Soft Light"), url("{{ BEACHAROUND_FONTS_FOLDER }}/CarosSoftLight.woff") format("woff");
  font-display: swap;
}
@font-face {
  font-family: "Caros";
  font-style: normal;
  font-weight: 500;
  src: local("Caros Soft Medium"), url("{{ BEACHAROUND_FONTS_FOLDER }}/CarosSoftMedium.woff") format("woff");
  font-display: swap;
}
@font-face {
  font-family: "Caros";
  font-style: normal;
  font-weight: 600;
  src: local("Caros Soft Bold"), url("{{ BEACHAROUND_FONTS_FOLDER }}/CarosSoftBold.woff") format("woff");
  font-display: swap;
}
@font-face {
  font-family: "Caros";
  font-style: normal;
  font-weight: 700;
  src: local("Caros Soft Heavy"), url("{{ BEACHAROUND_FONTS_FOLDER }}/CarosSoftHeavy.woff") format("woff");
  font-display: swap;
}
* {
  margin: 0px;
  padding: 0px;
  list-style: none;
  text-decoration: none;
}

body {
  background-color: #ffffff;
}

body, p {
  color: #3b3b3b;
  font-family: "Caros", Roboto, Helvetica Neue, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.4;
}

button {
  font-family: "Caros";
  font-weight: 500;
}

.text-blue {
  color: #3a80b4;
}

.text-white {
  color: #ffffff;
}

strong {
  font-weight: 600;
}

strike {
  text-decoration: line-through;
  opacity: 0.8;
}

del {
  text-decoration: line-through;
  opacity: 0.8;
}